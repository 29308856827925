var $ = require("jquery");
require("slick-carousel");
import "../stylesheets/slick";

//検索結果一覧js
$(function () {
  const spMax = 640;

  //==========
  //リストに追加された事務所スライダー
  //==========
  var slider = $(".js-result-slider");
  slider.slick({
    slidesToShow: 1,
    variableWidth: true,
    infinite: false
  });

  $(".result-action__confirm .js-toggle-trigger").click(function () {
    //スライダーの親要素のdisplay:noneにより表示崩れを直す処理
    slider.slick("setPosition");

    //スライドの高さを統一する処理
    //一番高さの高いスライドと高さを取得し、全てのスライドにその高さを適用する
    var sliderMaxHeight = 0;

    $(".js-result-slider li").each(function (i, e) {
      var height = $(e).height();
      if (sliderMaxHeight < height) {
        sliderMaxHeight = height;
      }
    });
    $(".js-result-slider li").height(sliderMaxHeight);
  });

  //==========
  //リストに追加された事務所　横移動
  //==========
  $(window).on("scroll", function () {
    $(".result-action").css("left", -$(window).scrollLeft());
  });

  //==========
  //sp用js
  //==========
  if ($(window).width() <= spMax) {
    // 条件を変更する画面表示
    $(".js-conditions-trigger").on("click", function (e) {
      e.preventDefault();

      var condition = $($(this).attr("href"));

      condition.addClass("is-open");
    });

    $(".js-conditions-close").on("click", function (e) {
      e.preventDefault();

      var condition = $($(this).attr("href"));

      condition.removeClass("is-open");
    });

    //条件を変更するボタン追従
    $(window).on("load scroll", function () {
      var btn = $(".js-conditions-fixed");
      var scrollTop = $(window).scrollTop();
      var goal = $(".conditions__box").offset().top;
      if (scrollTop > goal) {
        btn.addClass("is-shown");
      } else {
        btn.removeClass("is-shown");
      }
    });
  }

  //==========
  //税理士・会計事務所一覧 スライダー
  //==========
  if ($(".js-slider").length > 0) {
    if ($(window).width() <= spMax) {
      $(".js-slider").not(".slick-initialized").slick({
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true
      });
    } else {
      $(".js-slider.slick-initialized").slick("unslick");
    }
  }

  //==========
  //モーダル スライダー
  //==========
  $(".js-modal-trigger").on("click", function () {
    var target = $($(this).attr("href"));

    if (target.hasClass("is-open")) {
      return false;
    } else {
      target.addClass("is-open");
      overlayOn(target, target.data("fix"));

      if (target.find($(".js-detail-modal-slider"))) {
        var index = $(this).closest(".slick-slide").data("slick-index");
        $(".js-detail-modal-slider").slick("slickGoTo", index, true);
      }
    }
  });

  //========
  //条件をクリア 先頭で$宣言しているため挙動変化に注意
  //========
  $(".js--clear").on("click", function () {
    $(".js-prefecture-select").prop("selectedIndex", 0);
    $(".js-city-select").val("");
    $(".js-city-select").prop("disabled", true);
    $(".js-keyword").prop("value", "");
    $("input[type=checkbox]").prop("checked", false);
  });
});
